<template>
	<div>

		<div class="tk-header">
			<div class="tk-header-content">
				<div class="tk-query-travel-search">
					<yj-trip-type 
						:tripType="tkQuery.tripType" 
						:vipParams="vipParams" 
						@changeTripType="changeTripType"
					>
					</yj-trip-type>

					<yj-travel-apply 
						v-if="tkQuery.tripType == 1 && vipCorp.bookStyle == 2" 
						proType="10901"
						:ccsqdNoOrId="tkQuery.ccsqdId || tkQuery.ccsqdNo" 
						:routeId="tkQuery.routeId"
						@chooseTravelApply="chooseTravelApply" >
					</yj-travel-apply>

					<!-- 因公单订模式出行人 -->
					<el-input 
						v-if="tkQuery.tripType == 1 && vipCorp.bookStyle == 1" 
						prefix-icon="el-icon-user"
						v-model="cxrListStr" 
						class="tk-input" 
						@click.native="toChooseCxrList()" 
						placeholder="请选择出行人"
						readonly
					></el-input>


				</div>
				<div class="tk-query-serach">
					<!-- 单程、往返 -->
					<tk-travel-type 
						:travelType="tkQuery.travelType" 
						@changeTravelType="changeTravelType"
						:isGongAndCcsqd="isGongAndCcsqd"
					>
					</tk-travel-type>
					<!-- 城市选择 -->
					<div class="tk-query-city-list">
						<!-- 出发地 -->
						<yj-tk-city 
							@chooseCity="chooseDepCity" 
							:keywords="depKeyword" 
							:isCorpControl="true"
							:tripType="tkQuery.tripType" 
							:bookStyle="tkQuery.bookStyle" 
							style="display: inline-block;"
							@changeRouteRole2="chooseCcsqdDepCity"
						>
							<div slot="referenceContent">
								<div class="tk-query-city tk-query-city-dep">
									<div class="tk-query-city-v3">出发地</div>
									<el-input 
										class="tk-query-city-input" 
										size="large" 
										@focus="focus($event)"
										placeholder="可输入城市或机场" 
										v-model="inputCityDep"
										:readonly="isGongAndCcsqd && (changeRouteRole==0 || changeRouteRole == 3 || changeRouteRole == 2)"
										>
									</el-input>
								</div>
							</div>

						</yj-tk-city>
						<!-- 目的地 -->
						<yj-tk-city 
							@chooseCity="chooseArrCity" 
							:keywords="arrKeyword" 
							:isCorpControl="true"
							:tripType="tkQuery.tripType" 
							:bookStyle="tkQuery.bookStyle" 
							style="display: inline-block;"
							@changeRouteRole2="chooseCcsqdArrCity"
						>
							<div slot="referenceContent">
								<div class="tk-query-city tk-query-city-arr">
									<div class="tk-query-city-v3">目的地</div>
									<el-input 
										class="tk-query-city-input" 
										size="large" 
										@focus="focus($event)"
										placeholder="可输入城市或机场" 
										v-model="inputCityArr"
										:readonly="isGongAndCcsqd && (changeRouteRole==0 || changeRouteRole == 3 || changeRouteRole == 2)"
										>

									</el-input>
								</div>
							</div>
						</yj-tk-city>
						<!-- 切换城市 -->
						<!-- :disabled="false" -->
						<el-button 
							class="tk-query-switch-city" 
							icon="el-icon-refresh " 
							circle
							@click="changeDepAndArriveCity()" 
							:autofocus="true"
							:disabled="isGongAndCcsqd && (changeRouteRole==0 || changeRouteRole == 3)"
						>
						</el-button>
					</div>

					<!-- 日期 -->
					<!-- style="height:40px;" -->

					<tk-query-date 
						class="tk-query-date" 
						:travelType="tkQuery.travelType" 
						:depDate="tkQuery.depDate"
						:arrDate="tkQuery.arrDate" 
						@chooseDate="chooseDate" 
						:maxDay="bookDateRange.maxDay"
						:minDay="bookDateRange.minDay"
					></tk-query-date>
					<!-- 搜索按钮 -->
					<el-button 
						type="primary" 
						class="tk-search-btn" 
						:loading="loadingFlightList == 1"
						@click="queryTk()">
						    <span v-show="loadingFlightList != 1">搜索您的航班</span>
					    	<span v-show="loadingFlightList == 1">搜索航班中...</span>
					</el-button>

				</div>
			</div>

			<!-- 因公单订模式下出行人选择控件 -->
			<yj-cxr-list 
				:maxLength="maxLength" 
				:empInfo="empInfo" 
				:visible="visibleCxrList" 
				:cxrList="cxrList"
				@cancleCxrList="cancleCxrList" 
				@chooseCxrList="chooseCxrList"
			></yj-cxr-list>

		</div>
		
		
		<yj-city-station
			:ccsqdInfo.sync="ccsqdObj"
			:paramsInfo.sync="cityParamsInfo" 
			:visible="visibleHlyCity" 
			:changeRouteRole="changeRouteRole"
			@cancel="cancelHlyCity"
			@determine="chooseHlyCity"
		>
			
		</yj-city-station>

	</div>
</template>

<script>
	import {
mapGetters,
mapMutations, mapState
} from 'vuex'
	// // 单程和往返切换
	import TkTravelType from './TkTravelType.vue'
	// // 日期控件
	import mixin from '@/mixin'
import TkQueryDate from './TkQueryDate.vue'
	export default {
		mixins:[mixin.ccsqdMixin],
		components:{
			'tk-travel-type': TkTravelType,
			'tk-query-date': TkQueryDate,
		},
		data() {
			return {
				depKeyword: '',
				arrKeyword: "",
				maxLength:9,
				visibleCxrList:false, // 是否显示出行人
				visibleApplication:false, // 是否显示出差申请单
				
				visibleHlyCity:false,
				cityParamsInfo: { //当前城市行息
					cityType: 1,
					cityId:'',
					szm:'',
					proType:10901,
				},
				// ccsqdObj:{
				// 	detail:{},
				// },
			}
		},
		computed:{
			...mapState({
				tkQuery: state => state.tk.tkQuery,
				cxrList:state=>state.tk.cxrList,
				lsCxrList: state=> state.tk.lsCxrList,
				chooseCcsqd:state=>state.tk.chooseCcsqd,
			}),
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			ccsqdObj(){
				return this.chooseCcsqd || {detail:{},}
			},
			// 因公因私参数控制
			vipParams() {
				/**
				 *   t系统   1因私开启 2因公开启
				 *   et  1因公开启，2因私开启  这里做一下换
				 */
				return this.getVipParamterByParNo('20007',3);  //  与t系统参数相反 // 机票因公 ， 因私 开启
			},
			// 是否开启国际机票参数
			tripTypeTk20008(){
				return  this.getVipParamterByParNo('20008',0);  //  国际机票是否开启
			},
			/**
			 *  出差单行程的控制参数
			 *  10991
			 * 	0 不做限制 
			 *  1 严格限制  
			 *  2 行程中城市任意组合  
			 *  3 仅出发、到达内限制可选
			 *
			 **/  
			changeRouteRole(){
				return this.getVipParamterByParNo('10991',0,'value1',10901); 
			},
			inputCityDep: {
				get() {
					return this.tkQuery.depInputName
				},
				set(val) {
					this.SETTkQuery({
						depInputName: val
					})
					this.depKeyword = val;
				}
			},
			inputCityArr: {
				get() {
					return this.tkQuery.arrInputName
				},
				set(val) {
					this.SETTkQuery({
						arrInputName: val
					})
					this.arrKeyword = val;
				}
			
			},
			// 当前正在查询的去程，返程
			isQueryTravelType(){
				return this.tkQuery.isQueryTravelType
			},
			loadingFlightList(){
				return this.tkQuery.loadingFlightList
			},
			// 显示出行人字符串
			cxrListStr(){
				let list = this.cxrList.map((cItem,cIndex)=>{
					return cItem.empName
				})
				return list.join('、')
			},
			// 企业信息
			vipCorp(){
				return this.$common.getUserInfo().vipCorp || {}
			},
			// 当前登录人信息
			empInfo(){
				return this.$common.getUserInfo().empInfo || {}
			},
			// 当前出行人中的最小职级，和其差旅标准
			minzjObj() {
			  var minzjObj = {};
			  if (this.cxrList.length > 0 && this.tkQuery.tripType == 1) {
			    minzjObj = this.$common.getMinEmpRank(this.cxrList);
			  } else {
			    minzjObj = {};
			  }
			  return minzjObj
			},
			// 日期最小，和最大值对象
			bookDateRange(){
				// 方法来自于mixins
			    let dateRangeObj = this.getBookDateRange(10901,this.tkQuery.tripType,365,this.tkQuery.ccsqdId,this.tkQuery.routeId)
				return dateRangeObj
			},
			// 日期查询
			dateQuery(){
				let obj={
					depszm:this.tkQuery.depszm,
					arrszm:this.tkQuery.arrszm,
					depDate:this.tkQuery.depDate,
					arrDate:this.tkQuery.arrDate
				};
				return obj
			},
			// 判断当前是 因公并且是出差单模式
			isGongAndCcsqd(){
				var bool = false;
				if(this.tkQuery.tripType == 1 && this.tkQuery.bookStyle == 2){
					bool = true;
				} 
				return bool
			},
			
		},
		watch:{
			minzjObj: {
			  immediate: true, // 这句重要
			  deep:true,
			  handler(val) {
			    val = val || {};
			    let obj = {
			      empRank: val.empRank ,
			      useNameclbz: val.empName,
				  minEmpRankEmpId:val.empId
			    }
			    this.SETTkQuery(obj)
			    // 获取机票差旅政策
			    this.getClbz();
				// 获取机票当前当前差标人的差旅政策
				this.getMinEmpIdClzc();
			  }
			},
			// 如果当前是出差单，并且是因公模式，修改：
			//  只能是单程模式
			isGongAndCcsqd(val){
				if(val){
					this.SETTkQuery({travelType:1})
				}
			},
		},
		mounted(){
			if(this.cxrList.length == 0 && this.tkQuery.bookStyle==1){
				this.initCxrList();
			}
		},
		methods:{
			...mapMutations({
				SETTkQuery: 'tk/setTkQuery',
				SETCxrList:"tk/setCxrList",
				SETLsCxrList:"tk/setLsCxrList",
                SETTkEdit:"tk/setTkEdit",
				SETTkCCsqd:"tk/setTkCCsqd",
				
			}),
			// 修改单程和往返
			changeTravelType(val) {
				this.SETTkQuery({
					travelType: val
				});
			},
			// 修改正在查询的状态
			changeIsQueryTravelType(val){
				this.SETTkQuery({
					isQueryTravelType: val
				})	
			},
			// 更新tripType
			changeTripType(val) {
				
				// 不切换就不用往下走了
				if(this.tkQuery.tripType == val){
					return 
				}
				
				this.SETTkQuery({
					tripType: val
				})
				// 因私时清空出行人
				if(val == 2){
					this.SETCxrList([])
				}else{
					this.initCxrList();
				}
				this.$emit('changeTripType',val)
			},
			initCxrList(){
						// 因公是把出行人还原
						let cxrList = this.$common.deepCopy(this.lsCxrList)
					
					if(cxrList.length == 0 && this.vipCorp.bookStyle == 1){
						// 判断是否是企业订票员， 如果不是企业订票员，则 添加当前预订人为出行人
						if( !(this.empInfo.opers && this.empInfo.opers.indexOf(3)>-1) ){
							let empItem = {
								...this.empInfo,
								useCardType:1,
								cxrType:1,
								cxrName: this.empInfo.empName
							}
							cxrList.push(empItem)
							// this.empInfo.useCardType = '1'; // 当前出行人使用证件类型  1 身份证  2 护照  3 港澳通行证   4 台胞证  5 回乡证  6 台湾通行证  7 学生证  8 军官证  9 其他证件
							// this.empInfo.cxrType = '1'; //当前人的出行人类型   1.员工  2.常旅客 3. 其他
							// this.empInfo.psgType = '1'; // 默认 1  1成人 2儿童 3婴儿 
							// this.empInfo.cxrName = this.empInfo.empName; //当前出行人的中文名字
							this.SETCxrList(cxrList)
						}
					}

					this.SETCxrList(cxrList);
			},
			// 修改出差申请单
			chooseTravelApply(obj) {	
				let routeObj = obj.currentRoute || {};
				let cxrList = obj.cxrList || [];
				let ccsqdDetail = obj.detail || {};
				// 获取最低员工职级
				let minEmp = this.$common.getMinEmpRank(cxrList);
				let queryObj = {};
			
				// 如果是出行人变化，仅处理差旅标准
				if(obj.changeType =='cxrListChange' ){
					queryObj = {
						'empRank': minEmp.empRank, //   职级
						'useNameclbz': minEmp.cxrName || minEmp.empName, // 本次预订使用谁的差旅标准
						'minEmpRankEmpId':minEmp.empId, // 当前员工差旅标准的职级
						'clbzText': '', // 差旅标准   现已转移至航班列表进行查询  具体的差旅政策
					}
				}else{
					queryObj = {
						ccsqdNo:obj.ccsqdNo,
						ccsqdId:obj.ccsqdId,
						routeId:obj.routeId,
						"dep": routeObj.departCityName, //  出发城市
						"depszm": routeObj.departAirport, //  出发城市三字码
						"depCityType":routeObj.departCityInternational, // 城市类型 默认为查询国内机票
						depInputName: routeObj.departCityName + (routeObj.departAirportName? `(${routeObj.departAirportName || ''})`:'')  + `(${routeObj.departAirport})`, //仅本页面显示 
						"arr": routeObj.arriveCityName,//  到达城市
						"arrszm": routeObj.arriveAirport, //  到达城市三字码
						"arrCityType": routeObj.arriveCityInternational, //城市类型
						arrInputName: routeObj.arriveCityName + (routeObj.arriveAirportName? `(${routeObj.arriveAirportName || ''})`:'') + `(${routeObj.arriveAirport})`, //仅本页面显示
						
						depDate:routeObj.departDate , // 出发日期
						fyys:routeObj.fyys,  // 费用预算
						cabin:routeObj.cabin, // 舱位类型
						
						'empRank': minEmp.empRank, //   职级
						'useNameclbz': minEmp.cxrName || minEmp.empName, // 本次预订使用谁的差旅标准
						'minEmpRankEmpId':minEmp.empId, // 当前员工差旅标准的职级
						'clbzText': '', // 差旅标准   现已转移至航班列表进行查询  具体的差旅政策
						
					}
				}
				
                if(this.tkQuery.tripType==1){
                    let customizeFlag=obj?.detail?.customizeFlag;
                    let route=obj?.currentRoute;
                    let detail=obj?.detail;
                    let tkEditObj={
                            project:{
                                id:'',
                                projectCode:'',
                                projectName:'',
                            }
                        }
                        
                    if(customizeFlag==2){
                        tkEditObj.project.id=route?.cclx??''
                        tkEditObj.project.projectCode=route?.cclxCode??''
                        tkEditObj.project.projectName=route?.cclxMc??''
                        this.SETTkEdit(tkEditObj)
                    }else if(customizeFlag==1){
                        tkEditObj.project.id=detail?.projectId??''
                        tkEditObj.project.projectCode=detail?.projectCode??''
                        tkEditObj.project.projectName=detail?.projectName??''
                        this.SETTkEdit(tkEditObj)
                    }

                }
				
				// this.ccsqdObj = obj;
				this.SETTkCCsqd(obj);
				this.SETTkQuery(queryObj);
				this.SETCxrList(this.$common.deepCopy(cxrList) )
				this.SETLsCxrList(this.$common.deepCopy(cxrList) )
				// 获取差旅规则 转为watch，监听获取
				// this.getClbz();
			},
			// 获取当前差旅出行人的差旅政策
			getMinEmpIdClzc(){
					  //因私 或 未开启差旅政策  不匹配  || !this.userInfo.vipCorp.zcHcp || (this.userInfo.vipCorp.zcHcp == 0)
					  if(this.tkQuery.tripType == 2 ) {
					  	return false;
					  }
					  
					  var queryString = {
						  id:this.tkQuery.minEmpRankEmpId  
					  }
					  if(!queryString.id  || this.cxrList.length<1){
						  return
					  }
					  this.$conn.getConn('tkBook.getVipEmpBookScopeDetail')(queryString,(res)=>{
						  var data = res.data || {};
						  var obj = {
							  // 当前出行人头等公务舱标准
							  isDomesticBusinessClass: data.isDomesticBusinessClass || 0,
							  isDomesticFirstClass: data.isDomesticFirstClass || 0, // 国内头等
							  isDomesticHighClass: data.isDomesticHighClass || 0,
							  isInternationalBusinessClass: data.isInternationalBusinessClass || 0,
							  isInternationalFirstClass: data.isInternationalFirstClass || 0,
							  isInternationalHighClass: data.isInternationalHighClass || 0,
						  }
						  this.SETTkQuery(obj)
					  },(err)=>{
						  
					  })
			},
			// 获取当前的差旅标准
			getClbz(){
				//因私 或 未开启差旅政策  不匹配
				if(this.tkQuery.tripType == 2 || !this.vipCorp.zcGnjp  || (this.vipCorp.zcGnjp  == 0)) {
					return false;
				}
				var self = this;
				var queryString = {
					"empRank": this.tkQuery.empRank,
					"arriveAirport": this.tkQuery.arrszm,
					"departAirport": this.tkQuery.depszm
				};
				if(queryString.empRank !== 0 && !queryString.empRank) {
					return false
				};
				if(!queryString.arriveAirport || !queryString.departAirport) {
					return false
				};
				this.$conn.getConn('tkBook.getClzbByMinZj')(queryString,(res)=>{
					var data = res.data || {};
					let clbzText  = data.clbz || '';
					      this.SETTkQuery({
					        clbzText:clbzText
					      })
				},(err)=>{
					this.SETTkQuery({
					  clbzText:''
					})
				})
			},
			
			// 获取焦点时，选中输入框内文本
			focus(event){
				event.currentTarget.select();
			},
			//选择出发城市
			chooseDepCity(item) {
				
				let obj = {
					"dep": item.cityName, //  出发城市
					"depszm": item.threeCode, //  出发城市三字码
					"depCityType": item.international == '0'?0 : 1, //  默认为查询国内机票
					depInputName: item.cityInput, //
				}
				this.SETTkQuery(obj);
				this.depKeyword = '';
			},
			
			// 选择到达城市
			chooseArrCity(item) {
				let obj = {
					"arr": item.cityName, //  到达城市
					"arrszm": item.threeCode, //  到达城市三字码
					"arrCityType": item.international == '0'?0 : 1, //  默认为查询国内机票
					arrInputName: item.cityInput, //
				}
				this.SETTkQuery(obj);
			},
			// 交换城市
			changeDepAndArriveCity(){
				
				this.SETTkQuery({
					"dep": this.tkQuery.arr || '', //  出发城市
					"depszm": this.tkQuery.arrszm || '', //  出发城市三字码
					"depCityType":this.tkQuery.arrCityType == '0'?0 : 1, //  默认为查询国内机票
					depInputName: this.tkQuery.arrInputName || "", // 
					"arr": this.tkQuery.dep || '', //  到达城市
					"arrszm": this.tkQuery.depszm || '', //  到达城市三字码
					"arrCityType": this.tkQuery.depCityType == '0'?0 : 1, //
					arrInputName: this.tkQuery.depInputName || '', // 
				})
				
			},
			
			// 选择出差单限制城市回调
			chooseHlyCity(chooseItem){
				// airportName: "首都国际机场"
				// cityId: "10119"
				// cityInput: "北京(PEK)"
				// cityName: "北京"
				// threeCode: "PEK"
				// cityType：1  出发、到达
				if(chooseItem.cityType == 1){
					this.chooseDepCity(chooseItem)
				}else{
					this.chooseArrCity(chooseItem)
				}
				this.visibleHlyCity = false;
			},
			cancelHlyCity(chooseItem){
				this.visibleHlyCity = false;
			},
			// 选择出发城市 bool
			chooseCcsqdDepCity(bool){
				this.cityParamsInfo = { //当前城市行息
					cityType: 1,
					cityId:'',
					szm:this.tkQuery.depszm,
					proType:10901,
				};
				this.visibleHlyCity = true;
			},
			// 选择到达城市 bool
			chooseCcsqdArrCity(bool){
				this.cityParamsInfo = { //当前城市行息
					cityType: 2,
					cityId:'',
					szm:this.tkQuery.arrszm,
					proType:10901,
				};
				this.visibleHlyCity = true;
			},
			// 选择日期
			chooseDate(date) {
			
				if (Array.isArray(date)) {
					this.SETTkQuery({
						depDate: date[0],
						arrDate: date[1]
					});
				} else {
					this.SETTkQuery({
						depDate: date
					});
				}
			},
			// 修改日期
			changeDate(date){
				if(this.tkQuery.isQueryTravelType == 2){
					this.SETTkQuery({
						arrDate: date
					});
				}else{
					this.SETTkQuery({
						depDate: date
					});
				}
				
				// 切换日期查询航班
				this.queryTk();
				
			},
			// 点击查询机票操作
			queryTk() {
		
				// 查询前校验查询参数：
				
				var validObj = this.validQuery();
				
				if(!validObj.bool){
					this.$message.error(validObj.tips)
					return false
				}
				// // 加载航班列表   0 ：未查询  1：正在查询  2.查询有结果 3. 查询无结果 4.查询异常
				this.SETTkQuery({
					loadingFlightList: 1,
					isAutoQuery:1,
					tripTypeTk20008:this.tripTypeTk20008,
				});
				this.$emit('queryTk',true)
				
			},
			// 验证查询参数
			validQuery(){
				var obj = {
					bool:true,
					tips:"",
				}
				if(!this.tkQuery.depszm  || !this.tkQuery.dep){
					obj.bool = false;
					obj.tips = '请选择出发城市!'
					return obj
				}
				if(!this.tkQuery.arrszm  || !this.tkQuery.arr){
					obj.bool = false;
					obj.tips = '请选择到达城市!'
					return obj
				}
				
				if(!this.tkQuery.depDate ){
					obj.bool = false;
					obj.tips = '请选择出发日期!'
					return obj
				}else{
					if(this.$common.getDiffDay3(new Date(this.tkQuery.depDate),new Date()) < 0 ){
						obj.bool = false;
						obj.tips = '出发日期不能小于今天!'
						return obj
					}
				}
				
				if(this.tkQuery.travelType == 2 && !this.tkQuery.arrDate ){
					obj.bool = false;
					obj.tips = '请选择返程日期!'
					return obj
				}
				
				if(this.tkQuery.tripType == 1 ){
					if(this.cxrList.length == 0){
						obj.bool = false;
						if(this.tkQuery.bookStyle == 1){
							obj.tips = '您当前是因公模式预订,请选择出行人!'
						}else{
							obj.tips = '您当前是因公模式预订,请选择出差单!'
						}
						
						return obj
					}
				}
			
				// 只要一个国际城市出现，都需要判断是否开启国际机票查询预订
				if( this.tkQuery.depCityType == '0' || this.tkQuery.arrCityType == '0'){
					
					let configData = this.$common.getConfigData();
					if(this.tripTypeTk20008 == '0' || (this.tripTypeTk20008 == 1 && this.tkQuery.tripType == 2  ) || (this.tripTypeTk20008 == 2 && this.tkQuery.tripType == 1  )  ){
						obj.bool = false;
						obj.tips = `贵企业未开启【国际机票】查询预订业务！若需要预订,请联系企业对接人设置！`;
						if(configData.homePageType == 'pxhk'){
							obj.tips = `国外订票由于涉及落实签证及转机问题，可联系鹏翔航空24小时客服人员电话号码${configData.officalPhone}进行线下订票。`
						}
						console.log(obj)
						return obj
					}
				}

				
				return obj				
			},
			
			
			/*
			*  【选择出差申请单】
			* 
			* 
			* **/ 
			// 选择出差申请单
			toChooseApplicationList(){
				this.visibleApplication = true;
			},
			
			/*
			*
			*  出行人选择
			* **/
			
			
			// 单订模式、选择出行人
			toChooseCxrList(){
				this.visibleCxrList = true
			},
			// 选择出行人回调
			chooseCxrList(cxrList){
				var newCxrList = this.$common.chuLiIdCardList(cxrList,'10901');
				this.SETCxrList(newCxrList);
				this.visibleCxrList = false;
			},
			// 取消出行人弹窗处理
			cancleCxrList(){
				this.visibleCxrList = false
			},
		},

	}
</script>

<style scoped="scoped" lang="scss">
	.tk-header{
		text-align: left;
		background-color: white;
	}
	.tk-header-content {
		width: 1200px;
		margin: 0 auto;
		font-family: '微软雅黑';
		// 因公因私
		.tk-query-travel-search{
			margin: 18px 0;
			display: flex;
		}
		// 往返、城市、日期、查询按钮
		.tk-query-serach {
			margin: 18px 0;
			height: 53px;
			display: flex;
			align-items: center;
			// 城市
			.tk-query-city-list {
				height: 53px;
				display: flex;
				align-items: center;
				position: relative;
				.tk-query-city {
					width: 250px;
					height: 53px;
					position: relative;
					border: 1px solid #EBEEF2;
					// 城市label标题
					.tk-query-city-v3 {
						font-size: 12px;
						color: #999;
						position: absolute;
						top: 4px;
						left: 20px;
					}
					// 城市名称
					.tk-query-city-input {
						position: absolute;
						top: 0;
						left: 0;
	
						/deep/ .el-input__inner {
							background-color: transparent;
							padding: 26px 20px 9px 20px;
							height: 53px;
							line-height: 20px;
							font-size: 15px;
							color: #000000;
							border: none;
						}
					}
	
				}
	
				// 到达地样式处理
				.tk-query-city-arr .tk-query-city-v3 {
					left: 38px;
				}
	
				.tk-query-city-arr .tk-query-city-input {
					/deep/ .el-input__inner {
						padding-left: 38px;
					}
				}
				// 切换按钮样式
				.tk-query-switch-city {
					position: absolute;
					top: 10px;
					left: 232px;
					font-size: 15px;
				}
				.tk-query-switch-city:hover{
					    -webkit-animation: rotate .1s linear 1;
					    -moz-animation: rotate .1s linear 1;
					    animation: rotate .1s linear 1;
				}
			}
			//日期
			.tk-query-date{
				
			}
			 
			// 搜索航班按钮
			.tk-search-btn{
				width: 206px;
				height: 53px;
				border-radius: 0;
				font-size: 16px;
				font-weight: 500;
				
			}
			
		}
	
	}
	
	.tk-input{
		width: 200px;
	}
</style>
